import React from 'react'
import style from './obsazenostNastenka.module.css'
function KontaktyNastenka() {
   return (
   <div className='nastenka'>
      <div className={style.nastenka}>
         <div className='nastenkos'>
            <h1 className="detailedNastenka">Obsazenost</h1>
         </div>
      </div>
   </div>
   )
}

export default KontaktyNastenka
