import React from 'react'
import style from './CenikNastenka.module.css'
function VybaveniNastenka() {
   return (
      <div className='nastenka'>
         <div className={style.nastenka}>
         <div className='nastenkos'>
            
            <h1 className="detailedNastenka">Ceník</h1>
         </div>
         </div>
      </div>
   )
}

export default VybaveniNastenka
