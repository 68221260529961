export const Links = [

{to:"/",form:"ÚVOD"

},{to:"/aktivity",form:"AKTIVITY"

},{to:"/galerie",form:"FOTOGALERIE"

},{to:"/cenik",form:"CENÍK"

},{to:"/obsazenost",form:"OBSAZENOST"

},{to:"/vybaveni",form:"VYBAVENÍ"

}]