import React from 'react'
import Nastenka from "./ObsazenostNastenka"
function Obsazenost() {
    return (
        <div >
        <Nastenka /><div className='obsazenost'>

<iframe  title="obsazenost" src="https://obsazenost.e-chalupy.cz/kalendar.php?id=7322&pocetMesicu=16&velikost=3&legenda=ano&vybraneMesice=&naStred=ano&ctvrtleti=ne&stin=ne&jazyk=cz&jednotky=ano&idJednotky=0&vypisJednotky=ne&souhrnny=&pozadi=ffffff&kalendarText=000000&kalendarPozadi=ffffff&ramecek=dddddd&mesicText=555555&mesicPozadi=dddddd&dnyText=008000&dnyPozadia=ffffff&obsazenoText=222222&obsazenoPozadi=f74848&volnoText=222222&volnoPozadi=67cf67&castecneText=222222&castecnePozadi=489ce0&neaktivniDnyText=bbbbbb&neaktivniDnyPozadi=ffffff&legendaText=000000&fontFamily=Verdana&extCss=" height="460px" width="100%" frameborder="0" id="echalupy-kalendar"></iframe> </div>
        </div>
    )
}

export default Obsazenost
