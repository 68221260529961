import React from 'react'
import style from './nastenka.module.css'
function nastenka() {
   return (
            <div className='nastenka'>
            <div className={style.nastenka}>
            <div className='nastenkos'>

            <h1 className="detailedNastenka">Fotogalerie</h1>
            </div>
            </div>
            </div>
   )
}

export default nastenka
