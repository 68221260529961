import VybaveniNastenka from './vybaveniNastenka'
import React from 'react'

import style from "./vybaveni.module.css"
function Vybaveni() {

    return (
        <div>
            <VybaveniNastenka/>
<div>
<div className={style.aktivity}>
            <h1 className={style.nadpis}>
               <span className={style.first}>První</span> patro
            </h1>
        
            <ul>
           <h3 className={style.h3}>Nabízíme vám ubytování v 10-ti pokojích.</h3> 
<li>

3x dvojlůžkových
</li>
<li>

4x čtyřlůžkových 
</li>
<li>

1x pětilůžkový family room- ve dvou pokojich (3+2) -společné soc.zařizení
</li>
<li>
    
1x sedmilůžkový family room  - ve dvou pokojích (5+2) - společné soc zařízení
</li>
<li>
    
Celková kapacita 34 osob.
</li>
<li>

	Každý pokoj je vybaven vlastním sociálním zařízením.
</li>
<li>

WiFi je k dispozici zdarma v celém objektu.
</li>
<li>

Možnost postýlek pro malé děti
</li>

</ul>

            <h1 className={style.nadpis}>
               <span className={style.first}>Pří</span>zemí
            </h1>
            <ul>
<li>

Veranda s posezením a dětským koutkem
</li>
<li>

Jídelna s výčepem (včetne chlazení a naražečů na KEG sudy)
</li>
<li>

Kompletně vybavená prostorná kuchyň  
</li>
<li>
    
WC - dámske panské
</li>
<li>
    
Parkování pro hosty je možné na přilehlém parkovišti zdarma.
</li>



</ul>
<h1 className={style.nadpis}>
               <span className={style.first}>Ven</span>kovní terasa
            </h1>
            <ul>
                <li>Terasa s posezením
</li>
<li>Možnost grilování a opékaní buřtů</li>
            </ul>

            <h1 className={style.nadpis}>
               <span className={style.first}>Co</span> na chatě není?
            </h1>
            <ul>
                <li>            Pantofle, utěrky na nádobí, ručníky, gel do myčky, houbičky na nádobí, drátěnku na nádobí, jar, toaletní papír, pytle na odpadky 60l a 80l. Vše je potřeba mít vlastní. Věci na závěreční úklid jsou k dispozici.

</li>

            </ul>
        
            <h1 className={style.nadpis}>
               <span className={style.first}>Cena</span>    pobytu nezahrnuje
            </h1>
            <ul>
                <li>          Turistický poplatek 20 Kč na dosp. Os. na den, dřevo do krbu, ručníky a ostatní hygienické potřeby.

</li>

            </ul>    <h1 className={style.nadpis}>
               <span className={style.first}>Úklid</span>
            </h1>
            <ul>
                <li>      Závěrečný úklid, každý provádí sám    
</li>

            </ul>


</div>

</div>
        </div>
        
    )
}

export default Vybaveni
