import React from 'react';
import Nastenka from './AktivityNastenka';
import style from './aktivity.module.css';

function Aktivity() {
   return (
      <div>
         <Nastenka />
         <div className={style.aktivity}>
            <h1 className={style.nadpis}>
               <span className={style.first}>Letní</span> Aktivity
            </h1>
            <p>
               Zde jsou ideální podmínky pro turistiku a cykloturistiku. Hlavní trasy se táhnou hřebenem Orlických hor, na který je možné vyjet i autem nebo cyklobusem. Zdobnice se nachází přímo v srdci chráněné krajinné oblasti Orlické hory a uprostřed hlavní cyklotrasy spojující Deštné v Orlických horách, Říčky a Bartošovice. Na své si zde přijdou i milovníci houbaření. V obci je také nové veřejné dětské hřiště s samoobslužným barem U zdobnických čertů, přímo u tréninkové trasy pro singltrack Zdobice, kde naleznete servisní stanici pro kola a dobíjecí stanici elektrokol včetně posezení.
            </p>

            <h1 className={style.nadpis}>
               <span className={style.first}>Zimní</span> Aktivity
            </h1>
            <p>
               V zimních měsících máme odtud, z Chaty, 300 metrů vzdálené skvělé lyžařské středisko Ski centrum Zdobnice, které nabízí celkem čtyři sjezdovky a tři lyžařské vleky. Dostatek sněhu zajišťuje výkonný systém umělého zasněžování. Samozřejmostí je půjčovna a servis lyží, lyžařská škola a školka s vlastním provazovým vlekem. Poloha uprostřed chráněné krajinné oblasti Orlické hory je rovněž výhodná pro milovníky běžeckého lyžování. K 220 kilometrům strojově upravovaných běžeckých tras je od roku 2014 upravován i Zdobnický okruh, který spojuje Zdobnici s obcí Říčky v Orlických horách.
            </p>

            <p>
               Zde jsou také zajímavá místa k navštívení:
               Hrad Potštejn,
               Kostelec nad Orlicí,
               Rychnov nad Kněžnou,
               Zámek Opočno,
               Hospital Kuks,
               Zámek Náchod,
               Zámek Nové Město nad Metují,
               Zámek Častolovice,
               Zámek Doudleby,
               Hrad Litice,
               Hrad Potštejn,
               Hrad Žampach,
               Hrad Kunětická Hora,
               Vila Nova Uhřínov,
               Třebechovické muzeum Betlémů,
               Muzeum stavebnice Merkur Police nad Metují,
               Muzeum Boženy Němcové Česká Skalice,
               První vojensko-historické muzeum M. Frosta,
               Podorlický skanzen Krňovice,
               Hořiněves - rodný dům Václava Hanky,
               Muzeum řemesel Nový Dvůr – Letohrad,
               Muzeum krajky Vamberk,
               Dělostřelecká tvrz Hanička,
               Dělostřelecká tvrz Bouda,
               Pěchotní srub K-S5 "U Potoka".
            </p>
         </div>
      </div>
   );
}

export default Aktivity;